import React from 'react'

import Layout from '../components/layout'
import Head from '../components/head'

import textStyles from '../components/text.module.scss'

const ImprintPage = () => {
    return (
        <Layout>
            <Head title="Imprint -"/>
            <p className={textStyles.text}>Publisher
            <br/>Riko Pinders
            <br/>
            <br/>Contact
            <br/>riko.pinders@web.de
            </p>
        </Layout>
    )
}

export default ImprintPage